<template>
<div class="container-fluid">
  <div class="p-lg-2">
    <h2 class="roboto bold">Edit Password</h2>
    <b-form ref="form" class="pt-5" @submit.prevent>
      <!-- Old Password -->
      <div class="row mb-2">
        <div class="col-md-2 pt-2">Current Password</div>
        <div class="col-md-7">
          <InputText
            type="password"
            v-model="$v.formData.old_password.$model"
            placeholder="Current Password"
            :disableAutoComplete="true"
            :classComponent="{
              'is-invalid-custom': $v.formData.old_password.$error,
            }"
            :invalidMessage="[
              !$v.formData.old_password.required
                ? 'Current password is required.'
                : null,
            ]"
          />
        </div>
      </div>
      <!-- New Password -->
      <div class="row mb-2">
        <div class="col-md-2 pt-2">New Password</div>
        <div class="col-md-7">
          <InputText
            type="password"
            v-model="$v.formData.new_password.$model"
            placeholder="New Password"
            :disableAutoComplete="true"
            :classComponent="{
              'is-invalid-custom': $v.formData.new_password.$error,
            }"
            :invalidMessage="[
              !$v.formData.new_password.required
                ? 'New password is required.'
                : null,
              !$v.formData.new_password.passwordPattern
                ? 'Your new password must have at least 1 letter and 1 number'
                : null,
              !$v.formData.new_password.minLength
                ? 'Mew password must be ' +
                  $v.formData.new_password.$params.minLength.min +
                  ' characters! ' +
                  ($v.formData.new_password.$params.minLength.min -
                    $v.formData.new_password.$model.length) +
                  ' characters left'
                : null,
            ]"
          />
        </div>
      </div>
      <!-- Confirm Password -->
      <div class="row mb-2">
        <div class="col-md-2 pt-2">Confirm Password</div>
        <div class="col-md-7">
          <InputText
            type="password"
            v-model="$v.formData.confirm_password.$model"
            placeholder="Confirm Password"
            :disableAutoComplete="true"
            :classComponent="{
              'is-invalid-custom': $v.formData.confirm_password.$error,
            }"
            :invalidMessage="[
              !$v.formData.confirm_password.required
                ? 'Confirm password is required.'
                : null,
              !$v.formData.confirm_password.sameAsPassword
                ? 'Confirm password must be identical with new password!'
                : null,
            ]"
          />
        </div>
      </div>
      <hr />
      <div class="text-right">
        <button type="button" class="btn btn-info" @click="submitData()">
          Save
          <font-awesome icon="spinner" spin v-if="isLoading"/>
        </button>
      </div>
    </b-form>
  </div>
</div>
</template>

<script>
import InputText from "@/components/formCustom/InputText";

import { regex, toaster } from "@/_helpers";
import { required, minLength, sameAs, helpers } from "vuelidate/lib/validators";

const passwordPattern = helpers.regex("passwordPattern", regex.passwordPattern);

const initialData = () => ({
  formData: {
    old_password: "",
    new_password: "",
    confirm_password: "",
  },
  isLoading: false,
});

export default {
  name: "ChangePassword",
  components: {
    InputText,
  },
  data() {
    return initialData();
  },
  validations: {
    formData: {
      old_password: {
        required,
      },
      new_password: {
        required,
        passwordPattern,
        minLength: minLength(6),
      },
      confirm_password: {
        required,
        sameAsPassword: sameAs("new_password"),
      },
    },
  },
  methods: {
    async submitData() {
      try {
        this.isLoading = true
        this.$v.$touch();
        if (this.$v.$error) {
          toaster.make("Please fill in the form correctly", "danger");
          const errors = Object.keys(this.$v.formData)
            .map((el) => {
              if (!/^\$/.test(el))
                return {
                  form: el,
                  data: this.$v.formData[el],
                };
            })
            .filter((el) => el && el.data.$invalid);
          console.error("Invalid form data", errors);
          this.isLoading = false
        } else this.changePassword();
      } catch (e) {
        this.isLoading = false
        console.error(e);
      }
    },
    async changePassword() {
      try {
        const formData = JSON.parse(JSON.stringify(this.formData));
        const body = {
          old_password: formData.old_password,
          new_password: formData.new_password,
          confirm_password: formData.confirm_password,
        };
        const res = await this.$api.user.changePassword(body);
        if (res.status === 200) {
          // login to update the new access token for merchant
          const loginRes = await this.$api.user.login(this.$store.getters.getMerchantData.merchant_email, formData.new_password)
          if(loginRes.status === 200) {
            toaster.make("Your password has been updated.", "success");
            this.$router.replace("/")
            this.isLoading = false
          }
        } else {
          toaster.make(res.data.message, "danger")
          this.isLoading = false
        } 
      } catch (e) {
        this.isLoading = false
        console.error(e);
      }
    },
  },
};
</script>
