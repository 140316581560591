var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"p-lg-2"},[_c('h2',{staticClass:"roboto bold"},[_vm._v("Edit Password")]),_c('b-form',{ref:"form",staticClass:"pt-5",on:{"submit":function($event){$event.preventDefault();}}},[_c('div',{staticClass:"row mb-2"},[_c('div',{staticClass:"col-md-2 pt-2"},[_vm._v("Current Password")]),_c('div',{staticClass:"col-md-7"},[_c('InputText',{attrs:{"type":"password","placeholder":"Current Password","disableAutoComplete":true,"classComponent":{
              'is-invalid-custom': _vm.$v.formData.old_password.$error,
            },"invalidMessage":[
              !_vm.$v.formData.old_password.required
                ? 'Current password is required.'
                : null,
            ]},model:{value:(_vm.$v.formData.old_password.$model),callback:function ($$v) {_vm.$set(_vm.$v.formData.old_password, "$model", $$v)},expression:"$v.formData.old_password.$model"}})],1)]),_c('div',{staticClass:"row mb-2"},[_c('div',{staticClass:"col-md-2 pt-2"},[_vm._v("New Password")]),_c('div',{staticClass:"col-md-7"},[_c('InputText',{attrs:{"type":"password","placeholder":"New Password","disableAutoComplete":true,"classComponent":{
              'is-invalid-custom': _vm.$v.formData.new_password.$error,
            },"invalidMessage":[
              !_vm.$v.formData.new_password.required
                ? 'New password is required.'
                : null,
              !_vm.$v.formData.new_password.passwordPattern
                ? 'Your new password must have at least 1 letter and 1 number'
                : null,
              !_vm.$v.formData.new_password.minLength
                ? 'Mew password must be ' +
                  _vm.$v.formData.new_password.$params.minLength.min +
                  ' characters! ' +
                  (_vm.$v.formData.new_password.$params.minLength.min -
                    _vm.$v.formData.new_password.$model.length) +
                  ' characters left'
                : null,
            ]},model:{value:(_vm.$v.formData.new_password.$model),callback:function ($$v) {_vm.$set(_vm.$v.formData.new_password, "$model", $$v)},expression:"$v.formData.new_password.$model"}})],1)]),_c('div',{staticClass:"row mb-2"},[_c('div',{staticClass:"col-md-2 pt-2"},[_vm._v("Confirm Password")]),_c('div',{staticClass:"col-md-7"},[_c('InputText',{attrs:{"type":"password","placeholder":"Confirm Password","disableAutoComplete":true,"classComponent":{
              'is-invalid-custom': _vm.$v.formData.confirm_password.$error,
            },"invalidMessage":[
              !_vm.$v.formData.confirm_password.required
                ? 'Confirm password is required.'
                : null,
              !_vm.$v.formData.confirm_password.sameAsPassword
                ? 'Confirm password must be identical with new password!'
                : null,
            ]},model:{value:(_vm.$v.formData.confirm_password.$model),callback:function ($$v) {_vm.$set(_vm.$v.formData.confirm_password, "$model", $$v)},expression:"$v.formData.confirm_password.$model"}})],1)]),_c('hr'),_c('div',{staticClass:"text-right"},[_c('button',{staticClass:"btn btn-info",attrs:{"type":"button"},on:{"click":function($event){return _vm.submitData()}}},[_vm._v(" Save "),(_vm.isLoading)?_c('font-awesome',{attrs:{"icon":"spinner","spin":""}}):_vm._e()],1)])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }